























































































import { Component, Prop, Mixins } from 'vue-property-decorator';
import { DataTableHeader, PagingAndSorting } from '@/shared/models';
import { ContratApporteursAffaires } from '@/models';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';
import { ContratApporteursAffairesCriteria } from '@/views/Administration/GestionApporteursAffaires/ContratApporteursAffairesCriteria.model';
import { ApiService } from '@/services/base/ApiService';
import { Resultats } from '@/shared/enums'
import moment from 'moment'

@Component({
    name: 'ContratApporteurAffairesList',
})
export default class ContratApporteurAffairesList extends Mixins(GrilleMixin) {
    private columns: DataTableHeader[] = [
        { text: 'Nom du contrat' },
        { text: 'Affecté à' },
        { text: 'Date de début', sortBy: 'dateDebut' },
        { text: 'Date de fin' },
        { text: 'Volumes CEE\nclassique' },
        { text: 'Volumes CEE\nprécarité' },
        { text: 'Part client\nCEE classique' },
        { text: 'Part client\nCEE précarité' },
        { text: 'Part de\nl\'apporteur d\'affaires' },
        { text: 'Prix fixé\nau contrat' },
        { text: 'Flux financier\ngéré par l\'apporteur d\'affaires' },
    ];

    private contratApporteursAffaires: ContratApporteursAffaires[] = new Array<ContratApporteursAffaires>();

    private loading: boolean = false;

    @Prop({ default: false })
    private editMode: boolean;

    private totalContratsApporteurAffaires: number;

    private resultats: Resultats[] = [Resultats.ALL, Resultats.ACTIF, Resultats.INACTIF];

    private resultat: number = Resultats.ACTIF.toKey();
    /**
     * Le critère de recherche de la grille.
     */
    private contratApporteursAffairesCriteria: ContratApporteursAffairesCriteria = new ContratApporteursAffairesCriteria();

    public mounted() {
        this.getData();
    }

    public created() {

        const utilisateurId = parseInt(this.$route.params.id);

        // Si on est sur une page avec un utilisateur défini, on utilise son identifiant dans les critères.
        if (utilisateurId) {
            this.contratApporteursAffairesCriteria.utilisateurId = utilisateurId;
        }           
    }

    /*
     * Récupération des données.
     */
    public getData() {
        this.contratApporteursAffairesCriteria.pagingAndSorting = PagingAndSorting.buildFromPaginationObject(this.pagination);
        this.contratApporteursAffairesCriteria.resultats = this.resultat;
        this.getContratApporteursAffaires(this.contratApporteursAffairesCriteria);
    }

    /**
     * Mise en forme de la date en 'DD/MM/YYYY'
     * @param value Date à formatter.
     */
    public formatterDate(value: string): string {
        if (value) {
            return moment(String(value)).format('DD/MM/YYYY');
        }
        return '';
    }

    /**
     * Récupération des contrats apporteur d'affaires.
     * @param criteria Le critère de recherche avec la pagination.
     */
    public getContratApporteursAffaires(criteria: ContratApporteursAffairesCriteria) {
        this.loading = true;
        // Si tu lis cette ligne, je suis désolé.
        const endpoint = this.$route.name === 'contrats-apporteurs-affaires'
            ? 'obtenirTous'
            : this.$route.name === 'modification-profil'
                ? 'obtenirTousParUtilisateurConnecte'
                : 'obtenirTousParUtilisateur';

        const contratAAService = new ApiService<ContratApporteursAffaires>(`contratApporteursAffaires/${endpoint}`);

        return new Promise<ContratApporteursAffaires[]>((resolve, reject) => {
            return contratAAService.getWhere(criteria.queryString).then((response) => {
                this.contratApporteursAffaires = response.data.data;
                this.totalContratsApporteurAffaires = response.data.totalCount;
                this.$set(this.pagination, 'totalItems', this.totalContratsApporteurAffaires);
                resolve(response.data.data);
            })
                .catch((error: { response: any; }) => {
                    reject(error.response);
                })
                .finally(() => this.loading = false);
        });
    }

}
