import { CriteriaBase } from '@/shared/models';
import { Resultats } from '@/shared/enums';

export class ContratApporteursAffairesCriteria extends CriteriaBase {
    /**
     * Les contrats actif.
     *
     * @type {boolean}
     * @memberof ContratApporteurAffairesCriteria
     */
    public resultats: number = Resultats.ALL.toKey();

    /**
     * Texte de recherche.
     *
     * @type {string}
     * @memberof ContratApporteurAffairesCriteria
     */
    public recherche: string = '';

    /**
     * Identifiant utilisateur.
     *
     * @type {number}
     * @memberof ContratApporteurAffairesCriteria
     */
    public utilisateurId: number = 0;
}