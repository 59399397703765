import { Component, Mixins } from 'vue-property-decorator';
import template from './GestionApporteursAffaires.Template.vue';
import ContratApporteurAffairesList from '@/components/Commun/ContratApporteurAffairesList.vue';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';

@Component({
    ...template,
    name: 'GestionApporteursAffaires',
    components: {
        ContratApporteurAffairesList,
    }
})
export default class GestionApporteursAffaires extends Mixins(GrilleMixin) {

    /**
     * Indique si on peut modifier le contrat.
     */
    public canEdit: boolean = true;
}
